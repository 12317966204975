
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Hero from '@glise/bricks/HomePage/Hero/Hero';
import ProductOverview from '@glise/bricks/HomePage/ProductOverview/ProductOverview';
import Faq from '@glise/bricks/HomePage/Faq/Faq';
import PricingPlans from '@glise/bricks/HomePage/PricingPlans/PricingPlans';
import ConnectTools from '@glise/bricks/HomePage/ConnectTools/ConnectTools';
import ChromeExtension from '@glise/bricks/HomePage/ChromeExtension/ChromeExtension';
import Features from '@glise/bricks/HomePage/Features/Features';
import Container from '@mui/material/Container';
import axios from 'axios';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { API_URL } from '@glise/api/helpers';
import { currentHost } from '@glise/utils/constants';
import Head from 'next/head';
function Home({ faqArr, pricingPlans, }: InferGetStaticPropsType<typeof getStaticProps>) {
    return (<>
      <Head>
        <link rel="alternate" hrefLang="en" href={`https://${currentHost}/en`}/>
        <link rel="alternate" hrefLang="es" href={`https://${currentHost}/es`}/>
        <link rel="canonical" href={`https://${currentHost}`}/>
      </Head>
      <Hero />
      {/*<HowDepartmentsUse />*/}
      <Features />
      <ProductOverview />
      <ConnectTools />
      <ChromeExtension />
      <Container>
        <PricingPlans pricingPlans={pricingPlans}/>
      </Container>
      <Faq faqArr={faqArr}/>
    </>);
}
// Fetch FAQ data at build time (SSG)
const getStaticProps: GetStaticProps = async () => {
    try {
        const faqRes = await axios.get(`${API_URL}api/v1/faqs?offset=0&limit=5`);
        const faqArr = faqRes?.data?.items || [];
        // Fetch Plans data from the API
        const pricingPlansRes = await axios.get(`${API_URL}api/v1/plans`);
        const pricingPlans = pricingPlansRes?.data?.plans || [];
        return {
            props: {
                faqArr,
                pricingPlans,
            },
            revalidate: 3600, // Revalidate every hour
        };
    }
    catch (error) {
        console.error('Error fetching FAQ data:', error);
        return {
            props: {
                faqArr: [],
            },
        };
    }
};
export default Home;

    async function __Next_Translate__getStaticProps__19489cff407__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19489cff407__ as getStaticProps }
  